<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Input v-model="carNumber"
                   placeholder="机构名称"
                   class="m-r-10"
                   style="width:160px" />
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex == 0">
        <div class="section">
          <div class="section-title">
            <span>运行情况</span>
          </div>
          <ul class="section-list">
            <li class="section-item"
                v-for="(item,index) in peopleAmountArr"
                :key="index">
              <span class="fs-14">{{item.title}}</span>
              <span class="fs-48 m-l-8">{{item.value}}</span><span v-if="index == 6">天</span>
            </li>
          </ul>
        </div>
      </template>
      <template v-if="currentTabIndex == 1">
        <div class="section">
          <div class="section-title">
            <span>运行情况</span>
          </div>
          <ul class="section-list">
            <li class="section-item"
                v-for="(item,index) in carAmountArr"
                :key="index">
              <span class="fs-14">{{item.title}}</span>
              <span class="fs-48 m-l-8">{{item.value}}</span><span v-if="index == 6">天</span>
            </li>
          </ul>
        </div>
      </template>
      <template v-if="currentTabIndex == 0">
        <div class="section">
          <div class="section-title">
            <span>机构人员构成</span>
          </div>
          <div class="flex three-echar">
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                作业大类分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="ylld"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                工种分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="ylld"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                年龄分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="ylld"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            <span>机构人员构成</span>
          </div>
          <div class="flex three-echar">
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                性别分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="xb"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                教育程度
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="jy"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                民族分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="mz"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            <span>政治面貌</span>
          </div>
          <div class="flex three-echar">
            <div class="three-echar-item">
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="zz"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="currentTabIndex == 1">
        <div class="section">
          <div class="section-title">
            <span>机构车辆构成</span>
          </div>
          <div class="flex three-echar">
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                作业大类分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="zydl"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                车辆类型分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="ylld"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                驱动方式分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="ylld"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            <span>机构车辆构成</span>
          </div>
          <div class="flex three-echar">
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                车辆所属权
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="xb"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                车辆厂商
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="jy"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
            <div class="three-echar-item">
              <p>
                <Icon custom="i-icon icon-saodi"
                      size="16"
                      color="rgb(0, 121, 254)"></Icon>
                车龄分布
              </p>
              <div class="item-echar">
                <Echarts width="350px"
                         height="450px"
                         key="mz"
                         :options="gcOptions"></Echarts>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
export default {
  components: {
    Echarts
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [{
        name: '人员分布'
      },
      {
        name: '车辆分布'
      }
      ],


      date: [],
      peopleAmountArr: [{
        title: '当前人数',
        value: '955'
      },
      {
        title: '离职人数',
        value: '2'
      },
      ],
      carAmountArr: [{
        title: '当前车辆数',
        value: '0'
      },
      {
        title: '报废车辆数',
        value: '0'
      },
      ],
      gcOptions: {
        legend: {
          bottom: '5%',
          left: 'center'
        },
        color: ['#37bbad', '#e9ab4e', '#4795d7', '#807cd4', '#83919b'],
        series: [{
          type: 'pie',
          radius: ['60%', '80%'],
          label: {
            show: false,
            position: 'center'
          },
          data: [{
            value: 111,
            name: '正常'
          },
          {
            value: 9,
            name: '维修'
          },
          {
            value: 5,
            name: '废弃'
          },
          {
            value: 8,
            name: '暂停'
          },
          {
            value: 2,
            name: '拆除'
          }
          ]
        }]
      },
      carNumber: '',
    };
  },
  computed: {

  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.getList()
    },
    getList () {

    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;

      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }

  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;

      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;

        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }

      .three-echar {
        height: 520px;
        padding: 16px 0px 62px;
        box-sizing: border-box;

        .three-echar-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .item-echar {
            height: 400px;
            display: flex;
            justify-content: center;
            border-right: 1px solid rgb(215, 215, 215);
          }
        }
      }

      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
